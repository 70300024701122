import { HttpErrorResponse } from '@angular/common/http';
import { ApolloError, isApolloError } from '@apollo/client/core';
import { DEFAULT_TRANSLATED_ERROR, ErrorTranslator, TranslatedError } from '@x/common/error';
import { GraphQLFormattedError } from 'graphql';

const DOMAIN_ERROR_TRANSLATIONS: Record<
  string,
  (extension: any) => { title: string; message: string }
> = {
  entity_not_found: ({ resource }: { resource?: string }) => ({
    title: `${resource ?? 'Resource'} not found`,
    message: `${resource ?? 'Resource'} does not exist`,
  }),
  'auth.invalid_credentials': () => ({
    title: $localize`Invalid Credentials`,
    message: $localize`Invalid email address or password. Please try again.`,
  }),
  'auth.invalid_token': () => ({
    title: $localize`Login Expired`,
    message: $localize`Login has expired, please sign in.`,
  }),
  'auth.unauthorized': () => ({
    title: $localize`Unauthorized`,
    message: $localize`Permission required to access resource.`,
  }),
  invalid_order_modification: ({ message, orderId, orderNumber }) => ({
    title: 'Invalid Order Modification',
    message: `${message} [Order ${orderNumber ? `#${orderNumber}` : `ID${orderId}`}]`,
  }),
  peach_card_payment_provider: ({ message }) => ({
    title: `Peach Card Provider Error`,
    message,
  }),
  peach_eft_payment_provider: ({ message }) => ({
    title: `Peach EFT Provider Error`,
    message,
  }),
  invalid_shipment_modification: ({ message }) => ({
    title: `Invalid Shipment Modification`,
    message,
  }),
  'icare.invalid_voucher_code': () => ({
    title: 'Invalid Voucher Code',
    message: `Invalid Voucher Code`,
  }),
  state_transition: ({ message }) => ({
    title: 'State Transition Error',
    message,
  }),
  stock_component_operation: ({ message }) => ({
    title: 'Stock Component Operation Error',
    message,
  }),
  order_template_operation: ({ message }) => ({
    title: 'Invalid Order Template Operation',
    message,
  }),
  bulk_order_process_orders: ({ message }) => ({
    title: 'Bulk Order Processing Error',
    message,
  }),
  sage_client_error_response: ({ message }) => ({
    title: 'Sage Client Error',
    message,
  }),
  aramex_shipping_provider: ({ message }) => ({
    title: 'Aramex Error',
    message,
  }),
  unsupported_payment_operation: ({ message }) => {
    return {
      title: 'Payment Operation error',
      message,
    };
  },
  media_content_sync: ({ message }) => {
    return {
      title: 'Media sync error',
      message,
    };
  },
  spreadsheet_import_validation_error: () => ({
    title: 'Import validation error',
    message: 'Spreadsheet import has validation errors',
  }),
  spreadsheet_import_parse_error: () => ({
    title: 'Import parsing error',
    message: 'Failed to parse spreadsheet import',
  }),
};

export class EcommerceDomainClientErrorTranslator implements ErrorTranslator {
  canTranslate(error: any): boolean {
    return (
      error instanceof ApolloError || isApolloError(error) || error instanceof HttpErrorResponse
    );
  }
  translateError(error: any): TranslatedError {
    if (error instanceof HttpErrorResponse) {
      return this.translateHttpErrorResponse(error);
    }

    if (error instanceof ApolloError || isApolloError(error)) {
      if (error.networkError && error.networkError instanceof HttpErrorResponse) {
        return this.translateHttpErrorResponse(error.networkError);
      }
      return this.translateApolloError(error);
    }

    return DEFAULT_TRANSLATED_ERROR;
  }

  translateHttpErrorResponse({ error }: HttpErrorResponse): TranslatedError {
    if ('code' in error && DOMAIN_ERROR_TRANSLATIONS[error.code]) {
      return {
        ...DOMAIN_ERROR_TRANSLATIONS[error.code](error.response),
        code: error.code,
        detail: JSON.stringify(error.response),
      };
    }

    return DEFAULT_TRANSLATED_ERROR;
  }

  translateApolloError(error: ApolloError): TranslatedError {
    if (error.graphQLErrors.length) {
      return this.translateGraphQLError(error.graphQLErrors[0]);
    }

    return DEFAULT_TRANSLATED_ERROR;
  }

  translateGraphQLError(error: GraphQLFormattedError): TranslatedError {
    const code = (error.extensions?.code as string) ?? error.message;

    if (DOMAIN_ERROR_TRANSLATIONS[code]) {
      return {
        ...DOMAIN_ERROR_TRANSLATIONS[code](error.extensions ?? {}),
        code,
        detail: JSON.stringify(error.extensions),
      };
    }

    return {
      ...DEFAULT_TRANSLATED_ERROR,
      message: (error.extensions?.message as string) ?? DEFAULT_TRANSLATED_ERROR.message,
      code,
    };
  }
}
